// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {
  Box,
  Button,
  Icon,
  IconButton,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {observer} from 'mobx-react';
import {useStores} from 'hooks/useStores';
import {ResponsiveDialog} from './ResponsiveDialog';
import {PageTitle} from '../ResourcesHeader';
import {LocationSearch} from 'modules/location';

export const CurrentLocation: ComponentType<{}> = observer((): Node => {
  const {locationStore} = useStores();
  const [dialogAnchorEl, setDialogAnchorEl] = useState(null);
  const [value, setValue] = useState(locationStore.currentLocation);
  const [isDirty, setIsDirty] = useState(false);

  const handleClose = () => {
    locationStore.saveCurrentLocation(value);
    setDialogAnchorEl(null);
    setIsDirty(false);
  };

  const handleReset = () => {
    setValue(null);
    setIsDirty(false);
    locationStore.saveCurrentLocation(null);
    setDialogAnchorEl(null);
  };

  const handleSelect = (newValue) => {
    setIsDirty(true);
    setValue(newValue);
  };

  return (
    <>
      <Button
        sx={styles.button}
        variant="text"
        onClick={(event) => setDialogAnchorEl(event.currentTarget.parentNode)}
        startIcon={
          <Icon name={IconNames.MapPin} color={Theme.colorPalette.primary} />
        }>
        {locationStore.currentLocationDisplayText || 'Set Location'}
      </Button>

      <ResponsiveDialog anchorEl={dialogAnchorEl} onClose={handleClose}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            <PageTitle />
            <Icon name={IconNames.MapPin} color={Theme.colorPalette.primary2} />
          </Stack>

          <IconButton
            icon={IconNames.Xmark}
            onClick={handleClose}
            aria-label="Close"
          />
        </Stack>

        <Box mt={1} mb={2.5}>
          <Typography>
            Enter a location to find resources in the area.
          </Typography>
        </Box>

        <LocationSearch value={value} onOptionSelect={handleSelect} />

        <Stack direction="row" mt={2} alignItems="center" spacing={1}>
          <Button
            disabled={!isDirty}
            sx={{
              ...styles.button,
              ...styles.locationButton,
            }}
            variant="contained"
            onClick={handleClose}>
            Set location
          </Button>

          <Button
            disabled={!value}
            sx={{
              ...styles.button,
              ...styles.locationButton,
            }} 
            onClick={handleReset}>
            Clear location
          </Button>
        </Stack>
      </ResponsiveDialog>
    </>
  );
});

const styles = {
  button: {
    textTransform: 'none',
  },
  locationButton: {
    marginTop: 2,
    maxWidth: {
      sm: '200px',
    },
    borderRadius: 12,
  }
};