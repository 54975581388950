// @flow
import React, {useEffect, useRef, useState} from 'react';
import type {Node} from 'react';
import {Events, Hooks, Models, Theme} from '@wellstone-solutions/common';
import {Box, DataGrid, usePaging} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {useMemberColumnMap} from '../../hooks';
import {ListHeader} from './ListHeader';
import {ALL_MEMBERS} from '../../constants';
import {AccessControl} from 'modules/rbac';
import {ROLES} from 'utils/Permissions';

type ParamsType = {
  role: string,
  program_id?: string,
};

const {usePaginatedData} = Hooks;
const {Member} = Models;
const DEFAULT_PARAMS: ParamsType = {role: ROLES.patient};
const EVENT_TABLE_NAME = 'MemberList';

const buildParams = (filter: string): ParamsType => {
  return filter !== ALL_MEMBERS
    ? {...DEFAULT_PARAMS, program_id: filter}
    : DEFAULT_PARAMS;
};

export const MemberList = (): Node => {
  const initialLoad = useRef(true);
  const {eventStore, meStore, RBACStore} = useStores();
  const initialFilter = meStore.myPrograms[0]?.id || ALL_MEMBERS;
  const [filter, setFilter] = useState(initialFilter);
  const [params, setParams] = useState(buildParams(initialFilter));

  const initialSortField = 'name';
  const initialSortOrder = 'asc';
  const initialState = {
    sorting: {
      sortModel: [{field: initialSortField, sort: initialSortOrder}],
    },
  };

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
    isPersisted: false,
    initialPageSize: 10,
  });

  const queryResults = usePaginatedData({
    url: Member.routes.index(meStore.me.membership.organization.id),
    params,
    dataTransformer: ({members, total}) => ({
      members: members.map(Member.toUI),
      total,
    }),
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        params,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, currentPage, pageSize, sortField, sortOrder]);

  const columnMap = useMemberColumnMap({
    refetch: refetchData,
    programId: filter,
  });

  const columns = [
    columnMap.nameLink,
    columnMap.birthdate,
    columnMap.identifiesAs,
    columnMap.lastOpened,
  ];

  if (!!meStore.ehrIntegrationOption) {
    // remove birthdate column and add EHR column
    const birthdateColumnIndex = columns.indexOf(columnMap.birthdate);
    columns.splice(birthdateColumnIndex, 1);
    columns.push(columnMap.ehr);
  }

  // Only admins/super admins can do actions right now
  if (RBACStore.hasAccess(AccessControl.members.viewListActions)) {
    columns.push(columnMap.actions);
  }

  if (filter !== ALL_MEMBERS) {
    columns.splice(
      3,
      0,
      columnMap.groups,
      columnMap.programStartDate,
      columnMap.programEndDate,
    );
  }

  const handleFilterChange = (updatedFilter) => {
    const updatedParams = buildParams(updatedFilter);
    setParams(updatedParams);
    setCurrentPage(0);
    setFilter(updatedFilter);
  };

  const handleSortChange = (sortedColumns) => {
    let updatedSortField = '';
    let updatedSortOrder = '';

    if (sortedColumns?.length > 0) {
      const [sortedColumn] = sortedColumns;
      updatedSortField = sortedColumn.field;
      updatedSortOrder = sortedColumn.sort;

      eventStore.addEvent(Events.USER_SORTED_TABLE, {
        sort_field: updatedSortField,
        sort_order: updatedSortOrder,
        table: EVENT_TABLE_NAME,
      });
    }

    setSortField(updatedSortField);
    setSortOrder(updatedSortOrder);
  };

  const handlePageChange = (updatedPage) => {
    setCurrentPage(updatedPage);
  };

  const handlePageSizeChange = (updatedPageSize) => {
    setPageSize(updatedPageSize);
  };

  return (
    <>
      <Box sx={{mb: 2}}>
        <ListHeader
          filter={filter}
          setFilter={handleFilterChange}
          refetch={refetchData}
        />
      </Box>

      <Box sx={styles.dateCells}>
        <DataGrid
          autoHeight
          getRowHeight={() => 'auto'}
          loading={queryResults.isLoading}
          rows={queryResults.data?.members ?? []}
          columns={columns}
          noRowsText="No members"
          page={currentPage}
          pageSize={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
          paginationMode="server"
          sortingMode="server"
          initialState={initialState}
          rowCount={queryResults.data?.total ?? 0}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onSortModelChange={handleSortChange}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {py: 1},
          }}
        />
      </Box>
    </>
  );
};

const styles = {
  dateCells: {
    '.startDate': {
      '&.date-cell-before': {
        fontWeight: Theme.typography.weight.bold,
      },
      '&.date-cell-soon': {
        color: Theme.colorPalette.positive,
      },
    },
    '.endDate': {
      '&.date-cell-before': {
        color: Theme.colorPalette.negative,
      },
      '&.date-cell-soon': {
        color: Theme.colorPalette.orange,
      },
    },
    '.date-cell-inactive': {
      color: Theme.colorPalette.dark,
      fontStyle: 'italic',
      '& .MuiDataGrid-cellContent': {
        // When italic, the overflow hides the top right of the text
        paddingRight: '2px',
      },
    },
  },
};
