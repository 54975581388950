// @flow

export const DISABLE_ADD_NEW_MEMBER = 'disable_add_new_member';
export const HIDE_MEMBERS_NAV = 'hide_members_nav';
export const HIDE_SEARCH_SITE = 'hide_search_site';
export const MAINTENANCE_MODE_BRIDGE = 'maintenance_mode_bridge';
export const NEW_GROUP_DETAIL = 'new_group_detail';
export const PATHFINDER_DATA_ACCESS = 'pathfinder_data_access';
export const QA_REVIEW = 'qa_review';
export const SECURITY_IDLE_TIMER = 'security_idle_timer';
export const SENTRY_DEBUG = 'sentry_debug';
export const SYSTEM_PAGES = 'system_pages';
export const TMP_EHR_INTEGRATION = 'tmp_ehr_integration';
export const TMP_STORY_MANAGER = 'tmp_story_manager';
export const TMP_PEERS_DOCUMENTATION = 'tmp_peers_documentation';
export const REVERT_DOCUMENT = 'revert_document';
export const TMP_SHARE_ANSWER = 'tmp_share_answer';
export const PENDO_ENABLED = 'pendo_enabled';
export const TMP_BRIDGE_WRAPPER = 'tmp_bridge_wrapper';
export const TMP_MEMBER_LANDING = 'tmp_member_landing';
export const HABITS_V2 = 'habits_v2';
export const STAFF_NOTIFICATIONS = 'staff_notifications';
export const AUTH_V2 = 'auth_v2';

export const hasFlag = (
  featureFlag: string,
  features: Array<{label: string}>,
): boolean => features.some((item) => item.label === featureFlag);

export const hasAnyFlags = (
  featureFlags: Array<string>,
  features: Array<{label: string}>,
): boolean => features.some((item) => featureFlags.includes(item.label));
