// @flow
import React, {useState} from 'react';
import type {ComponentType, Node} from 'react';
import {observer} from 'mobx-react';
import {useParams} from 'react-router-dom';
import {
  Box,
  Icon,
  IconNames,
  Stack,
  Typography,
} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';
import {useStores} from 'hooks/useStores';
import {PfButton} from 'components/shared/PfButton';
import {AddProgramModal} from './AddProgramModal';
import {MemberProgramAdmission} from './MemberProgramAdmission';
import {AccessControl} from 'modules/rbac';


export const Admissions: ComponentType<{}> = observer((): Node => {
  const {member: memberId} = useParams();
  const [showProgramModal, setShowProgramModal] = useState<boolean>(false);
  const {memberStore, RBACStore} = useStores();
  const member = memberStore.findMember(memberId);

  const canAddProgramEnrollment = RBACStore.hasAccess(
    AccessControl.members.canAddProgramEnrollment,
  );

  return (
    <Box sx={{flexGrow: 1, width: '100%', mt: 2}}>
      <Stack direction="column" justifyContent="flex-end" sx={{height: '100%'}}>
        {/* list of program admissions */}
        {member.admissions.map((programAdmission) => (
          <MemberProgramAdmission
            memberId={memberId}
            admission={programAdmission}
            key={programAdmission.id}
          />
        ))}
        {canAddProgramEnrollment && (
          <Stack direction="row" justifyContent="flex-end">
            <PfButton
              color="primary"
              onClick={() => setShowProgramModal(true)}
              style={styles.button}
              aria-label="add member to program"
              fullwidth={false}
              inverted>
              <Typography sx={{fontWeight: 'bold', mr: 1}}>
                Add Program Enrollment
              </Typography>
              <Icon
                color={Theme.colorPalette.primary}
                name={IconNames.PlusCircle}
                strokeWidth={2}
              />
            </PfButton>
          </Stack>
        )}
      </Stack>
      <AddProgramModal
        open={showProgramModal}
        setShowModal={setShowProgramModal}
      />
    </Box>
  );
});

const styles = {
  button: {
    borderSize: 0,
    paddingRight: '15px',
    width: '276px',
  },
  grantBox: {
    backgroundColor: Theme.colorPalette.seafoamLight,
  },
  grantBoxHeader: {
    backgroundColor: Theme.colorPalette.violet,
    color: Theme.colorPalette.lightest,
  },
  grantBoxBody: {
    color: Theme.colorPalette.violet,
  },
};
