// @flow
import React, {useState} from 'react';
import {observer} from 'mobx-react';
import type {ComponentType} from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import {getInitials} from 'utils/Utils';
import {
  Avatar,
  Typography,
  Divider,
  Stack,
  Button,
  Box,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {SiteHeaderDrawer} from './SiteHeaderDrawer';

type PropsType = {};

export const ProfilePanel: ComponentType<PropsType> = observer((props) => {
  const {meStore} = useStores();
  const theme = useTheme();
  const isAuthenticated = meStore.isAuthenticated();
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setProfileAnchorEl(null);
  };
  const handleClick = (event) => {
    let anchor =
      event.target.closest('.header_tool') ?? event.target.parentNode;
    setProfileAnchorEl(anchor);
  };

  return (
    <>
      {isAuthenticated && (
        <>
          <Avatar
            data-testid="header-profile-avatar"
            onClick={handleClick}
            onKeyPress={handleClick}
            alt="{meStore.me.name}"
            tabIndex={0}
            sx={isMobile ? style.avatarMobile : style.avatar}>
            {getInitials(meStore.me.name ?? '')}
          </Avatar>
          <SiteHeaderDrawer
            onClose={handleClose}
            title="Profile"
            anchorEl={profileAnchorEl}
            closeButton={false}
            transitionDirection="left"
            transitionTimeout={0}>
            <Stack direction="row" px={2} py={1} spacing={2}>
              <Box sx={style.profileBox}>
                <AccountCircleIcon
                  sx={{fontSize: 24, color: theme.palette.grey['500']}}
                />
              </Box>
              <Box>
                <Typography sx={style.profileName}>
                  {meStore.me?.name}
                </Typography>
                <Typography sx={style.profileEmail}>
                  {meStore.activeMembership?.user?.email}
                </Typography>
              </Box>
            </Stack>
            <Divider sx={{mt: 2, mb: 2}} />
            <Box sx={{padding: '8px 11px'}}>
              <Typography>
                <Button sx={style.profileSignout} onClick={meStore.signOut}>
                  Sign Out
                  <LogoutIcon
                    sx={{
                      display: 'inline-block',
                      marginLeft: 1,
                      fontSize: 22,
                    }}
                  />
                </Button>
              </Typography>
            </Box>
          </SiteHeaderDrawer>
        </>
      )}
    </>
  );
});

const style = {
  // GET THESE FROM THEME
  avatar: {
    cursor: 'pointer',
    backgroundColor: '#6DCDB8',
    color: '#000',
    width: 35,
    height: 35,
    fontSize: 16,
    lineHeight: 20,
  },
  avatarMobile: {
    cursor: 'pointer',
    backgroundColor: '#6DCDB8',
    color: '#000',
    width: 32,
    height: 32,
    fontSize: 14,
    lineHeight: 20,
  },
  profileBox: {
    display: 'flex',
    alignItems: 'center',
  },
  profileName: {
    fontWeight: 400,
  },
  profileEmail: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '14px',
  },
  profileSignout: {
    fontSize: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
};
