// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {Progress, Stack} from '@wellstone-solutions/web';
import {PfButton} from 'components/shared/PfButton';
import {Theme} from '@wellstone-solutions/common';
import {Tooltip} from '@mui/material';
import {PfConfirmInline} from 'components/shared/PfConfirmInline';

type PropsType = {
  item: any,
  ItemDetailsComponent: (props: {item: any}) => Node,
  removeListItem?: (item: any) => Promise<void>,
  removeOverride?: (item: any) => void,
};

export const CardDataListItem = ({
  item,
  ItemDetailsComponent,
  removeListItem,
  removeOverride,
}: PropsType): Node => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const onRemoveClick = () => {
    if (removeOverride) {
      removeOverride(item);
      return;
    }

    setShowConfirm(true);
  };

  const cancelRemove = () => {
    setShowConfirm(false);
  };

  const actionClickWrapper = async () => {
    setIsLoading(true);
    setShowConfirm(false);
    await removeListItem?.(item);
    setIsLoading(false);
  };

  const renderButton = () => {
    if ((!removeListItem && !removeOverride) || item.preventDelete) {
      return <></>;
    }

    if (isLoading) {
      return (
        <div style={styles.loading}>
          <Progress
            size="small"
            color="primary"
            style={styles.progress}
            data-testid="loading-spinner"
          />
        </div>
      );
    }

    if (showConfirm) {
      return (
        <PfConfirmInline
          showConfirm={showConfirm}
          confirm={actionClickWrapper}
          cancelConfirm={cancelRemove}
          color="danger"
        />
      );
    }

    return (
      <Tooltip title="Discharge from Group" placement="left">
        <div>
          <PfButton
            color=""
            onClick={onRemoveClick}
            style={styles.buttonOutline}>
            Discharge
          </PfButton>
        </div>
      </Tooltip>
    );
  };

  return (
    <li style={styles.listItem}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={styles.listContent}>
        <div>
          {/* $FlowFixMe */}
          <ItemDetailsComponent item={item} />
        </div>
        <div>{renderButton()}</div>
      </Stack>
    </li>
  );
};

const styles = {
  listItem: {
    borderBottom: `1px solid ${Theme.colorPalette.mediumLight}`,
    padding: '6px 18px',
  },
  listContent: {
    minHeight: '38px',
  },
  loading: {
    position: 'relative',
    right: '10px',
    top: '6px',
    height: '30px',
    width: '18px',
  },
  progress: {
    display: 'block',
  },
  buttonOutline: {
    border: `1px solid ${Theme.colorPalette.primary}`,
    color: Theme.colorPalette.primary,
  },
};
