// @flow
import moment from 'moment';
import type {UIMemberType} from '@wellstone-solutions/common';

type PropsType = {
  member: UIMemberType,
  selectedStartDate: moment$Moment,
  programId: string,
}

export const groupStartDatePrecedesProgramStartDate = ({member, selectedStartDate, programId}: PropsType): boolean => {
    let value = false;
    const programAdmission = member.admissions.find(
      (admission) => admission.program.id === programId,
    );

    if (!programAdmission) {
      return false;
    }
    
    const formattedStartDate = moment(selectedStartDate).format('YYYY-MM-DD');
    // $FlowIgnore
    const admissionDate = moment(programAdmission.start_date);
    const inputtedStartDate = moment(formattedStartDate);

    // if inputtedStartDate is before admissionDate, return false, which disables the checkbox
    if (inputtedStartDate.isAfter(admissionDate)) {
      value = true;
    }
    return value;
  };