// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {Box, Typography, Stack} from '@wellstone-solutions/web';
import {Events} from '@wellstone-solutions/common';
import type {UISessionDocumentType} from '../../model/types';
import {useOptimisticRows, PagedDataGrid} from 'modules/datagrid';
import {useStores} from 'hooks/useStores';
import {SessionDocument} from '../../model';
import {useSessionDocumentColumnMap} from '../../hooks';
import {DOCUMENT_STATUSES} from '../../constants';
import {ConfirmSubmit} from '../SessionDocument/View/ConfirmSubmit';
import {ConfirmDelete} from '../SessionDocument/ConfirmDelete';
import {ConfirmRevert} from '../SessionDocument/ConfirmRevert';
import {ListFilters} from './ListFilters';
import {getDocumentUrl} from '../../utils';

type PropsType = {
  columnNames: Array<string>,
  defaultParams?: {[string]: mixed},
  description?: string,
  showProgramFilter?: boolean,
  showMemberFilter?: boolean,
  showStaffFilter?: boolean,
  showGridHeader?: boolean,
  rowHeight?: () => Integer,
  showDocumentCount?: boolean,
  title?: string,
};

export const SessionDocumentList = ({
  columnNames,
  defaultParams = {},
  description = '',
  showProgramFilter = false,
  showMemberFilter = false,
  showStaffFilter = false,
  showGridHeader = true,
  rowHeight,
  showDocumentCount = false,
  title = '',
}: PropsType): Node => {
  const navigate = useNavigate();
  const {eventStore, meStore, sessionDocumentStore} = useStores();
  const [params, setParams] = useState(defaultParams);
  const [documentToDelete, setDocumentToDelete] = useState();
  const [documentToSubmit, setDocumentToSubmit] = useState();
  const [documentToRevert, setDocumentToRevert] = useState();

  const {
    optimisticRows,
    addOptimisticRow,
    clearOptimisticRows,
    setOptimisticRows,
  } = useOptimisticRows();

  const onConfirmDelete = (documentation: UISessionDocumentType) => {
    setDocumentToDelete(documentation);
  };

  const onDelete = async () => {
    if (!documentToDelete) {
      return;
    }

    const rollbackRows = [...optimisticRows];

    addOptimisticRow({id: documentToDelete.id, newData: false});

    const response = await sessionDocumentStore.removeSessionDoc(
      documentToDelete.id,
    );

    if (!response.isSuccess) {
      // rollback the optimistic update
      setOptimisticRows(rollbackRows);
    }
  };

  const onRevert = async () => {
    if (!documentToRevert) {
      return;
    }

    const rollbackRows = [...optimisticRows];

    addOptimisticRow({
      id: documentToRevert.id,
      newData: {
        ...documentToRevert,
        status: DOCUMENT_STATUSES.draft,
        reverted: {
          changedBy: meStore.me.id,
          changedAt: moment().toISOString(),
          reason: '',
        },
      },
    });

    const response = await sessionDocumentStore.updateSessionDocStatus(
      documentToRevert?.id,
      documentToRevert,
      DOCUMENT_STATUSES.draft,
    );

    if (response.isSuccess) {
      eventStore.addEvent(Events.REVERTED_SESSION_DOC, {
        document_id: response.data.id,
        owner_id: response.data.staff.id,
        admin_id: meStore.me.id,
      });
    }

    if (!response.isSuccess) {
      // rollback the optimistic update
      setOptimisticRows(rollbackRows);
    }
  };

  const onConfirmSubmitted = (documentation: UISessionDocumentType): void => {
    setDocumentToSubmit(documentation);
  };

  const onConfirmRevert = (documentation: UISessionDocumentType): void => {
    setDocumentToRevert(documentation);
  };

  const onSubmitted = async (submittedAt: string): Promise<void> => {
    if (!documentToSubmit) {
      return;
    }
    const rollbackRows = [...optimisticRows];

    addOptimisticRow({
      id: documentToSubmit.id,
      newData: {
        ...documentToSubmit,
        status: DOCUMENT_STATUSES.submitted,
        submittedAt: submittedAt,
        modified: moment(),
      },
    });

    const response = await sessionDocumentStore.updateSessionDoc(
      documentToSubmit.id,
      {...documentToSubmit, submittedAt, status: DOCUMENT_STATUSES.submitted},
    );
    if (!response.isSuccess) {
      // rollback the optimistic update
      setOptimisticRows(rollbackRows);
    }
  };

  const onFilterFormSubmit = (newParams): void => {
    // Update params, but do not override any of the defaults
    setParams({
      ...newParams,
      ...defaultParams,
    });
  };

  const closeDeleteModal = (): void => {
    setDocumentToDelete();
  };

  const closeSubmittedModal = (): void => {
    setDocumentToSubmit();
  };

  const closeRevertModal = (): void => {
    setDocumentToRevert();
  };

  const columnMap = useSessionDocumentColumnMap({
    onDeleteAction: onConfirmDelete,
    onSubmittedAction: onConfirmSubmitted,
    onRevertAction: onConfirmRevert,
  });

  const columns = columnNames.reduce((acc, columnName) => {
    if (columnMap[columnName]) {
      acc.push(columnMap[columnName]);
    }
    return acc;
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" sx={{mb: 1}}>
        <Box>
          {title && title.length > 1 && (
            <Typography variant="h5" color="darkest.main">
              {title}
            </Typography>
          )}
          {description && description.length > 0 && (
            <Typography variant="body2" color="darkest.main">
              {description}
            </Typography>
          )}
        </Box>

        <ListFilters
          showMemberFilter={showMemberFilter}
          showStaffFilter={showStaffFilter}
          showProgramFilter={showProgramFilter}
          onSubmit={onFilterFormSubmit}
        />
      </Stack>

      <PagedDataGrid
        columns={columns}
        params={params}
        dataTransformer={({data, total_count}) => ({
          rows: data.map(SessionDocument.toUI),
          total: total_count,
        })}
        initialSortField={columns[0].field}
        initialSortOrder="desc"
        noRowsText="No session documents"
        url={SessionDocument.routes.index(meStore.organizationId)}
        onRowClick={({row}) => navigate(getDocumentUrl(row.member.id, row.id))}
        sx={{
          '& .MuiDataGrid-cell': {
            cursor: 'pointer',
          },
        }}
        optimisticRows={optimisticRows}
        clearOptimisticRows={clearOptimisticRows}
        // When grid header is hidden we switch row background colors
        {...(!showGridHeader && {
          headerHeight: 0,
          getRowClassName: (params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even',
        })}
        {...(rowHeight && {getRowHeight: rowHeight})}
        {...(showDocumentCount ? {label: 'Document'} : {})}
      />

      <ConfirmSubmit
        documentation={documentToSubmit}
        open={Boolean(documentToSubmit)}
        setOpen={closeSubmittedModal}
        onConfirm={onSubmitted}
      />

      <ConfirmDelete
        open={Boolean(documentToDelete)}
        setOpen={closeDeleteModal}
        onConfirm={onDelete}
      />

      <ConfirmRevert
        open={Boolean(documentToRevert)}
        setOpen={closeRevertModal}
        onConfirm={onRevert}
      />
    </>
  );
};
