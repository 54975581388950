// @flow
import React from 'react';
import type {Node} from 'react';

import type {GridColDef, GridActionsColDef} from '@mui/x-data-grid';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import {useNavigate} from 'react-router-dom';
import {Theme} from '@wellstone-solutions/common';
import {
  GridActionsCellItem,
  Stack,
  Box,
  Typography,
  Icon,
  IconNames,
} from '@wellstone-solutions/web';
import {hasFlag, REVERT_DOCUMENT} from 'constants/FeatureFlags';
import {adminRoles} from 'utils/Forms';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';
import {getFullMomentDateTimes} from 'utils/getFullMomentDateTimes';
import {ListCell, NavigationCell, PLACEHOLDER_TEXT} from 'modules/datagrid';
import type {UISessionDocumentType} from '../../model/types';
import {getStatusDate, getDocumentUrl} from '../../utils';
import {DocumentCell} from '../../components/SessionDocumentList';

import {
  DATE_FORMAT_LONG,
  DOCUMENT_STATUSES,
  SESSION_TYPES,
} from '../../constants';
import {StatusChip} from '../../components/SessionDocument/StatusChip';

export type ColumnMapType = {
  actions: GridActionsColDef<UISessionDocumentType>,
  dateOfService: GridColDef<UISessionDocumentType>,
  didMemberAttend: GridColDef<UISessionDocumentType>,
  member: GridColDef<UISessionDocumentType>,
  sessionType: GridColDef<UISessionDocumentType>,
  staff: GridColDef<UISessionDocumentType>,
  status: GridColDef<UISessionDocumentType>,
  statusBadge: GridColDef<UISessionDocumentType>,
  typeOfService: GridColDef<UISessionDocumentType>,
};

type PropsType = {
  onDeleteAction?: (documentation: UISessionDocumentType) => void,
  onSubmittedAction?: (documentation: UISessionDocumentType) => void,
  onRevertAction?: (documentation: UISessionDocumentType) => void,
};

export const useSessionDocumentColumnMap = ({
  onDeleteAction,
  onSubmittedAction,
  onRevertAction,
}: PropsType): ColumnMapType => {
  const navigate = useNavigate();
  const {meStore, sessionDocumentStore, RBACStore} = useStores();

  const DateOfServiceCell = ({row}: {row: UISessionDocumentType}): Node => {
    if (!row.dateOfService) {
      return PLACEHOLDER_TEXT;
    }

    const dateOfService = moment(row.dateOfService).format(DATE_FORMAT_LONG);
    let duration = PLACEHOLDER_TEXT;

    const {start, end} = getFullMomentDateTimes(
      row.dateOfService,
      row.startTime,
      row.endTime,
    );

    if (start && end) {
      duration = humanizeDuration(end.diff(start, 'milliseconds'), {
        round: true,
      });
    }

    return (
      <NavigationCell
        text={dateOfService}
        subText={duration}
        to={getDocumentUrl(row.member.id, row.id)}
      />
    );
  };
  const DocumentDescriptionCell = ({
    row,
  }: {
    row: UISessionDocumentType,
  }): Node => {
    return (
      <DocumentCell
        document={row}
        showStaff={
          !RBACStore.hasAccess(AccessControl.documentation.viewMySessionDocs)
        }
      />
    );
  };

  const MemberCell = ({row}: {row: UISessionDocumentType}): Node => {
    const {member} = row;
    return <NavigationCell text={member.name} to={`/members/${member.id}`} />;
  };

  const StaffCell = ({row}: {row: UISessionDocumentType}): Node => {
    const {staff} = row;
    const role = adminRoles.find(({value}) => value === staff.role);
    return (
      <NavigationCell
        text={staff.name}
        subText={role?.name}
        to={`/staff/${staff.id}`}
      />
    );
  };

  const StatusCell = ({row}: {row: UISessionDocumentType}): Node => {
    const statusDate = getStatusDate({documentation: row});
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{width: '100%'}}>
        <StatusChip status={row.status} />
        <Typography variant="body2" noWrap>
          {statusDate}
        </Typography>
      </Stack>
    );
  };

  const StatusBadgeCell = ({row}: {row: UISessionDocumentType}): Node => {
    return (
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        <StatusChip status={row.status} />
      </Box>
    );
  };

  const ServiceTypeCell = ({row}: {row: UISessionDocumentType}): Node => {
    if (row.typeOfService.length === 0) {
      return PLACEHOLDER_TEXT;
    }

    return (
      <ListCell
        items={row.typeOfService.map(({name}) => name)}
        itemLabel="service"
      />
    );
  };

  const getRowActions = ({row}: {row: UISessionDocumentType}): Array<Node> => {
    const isOwner = meStore.activeMembership.id === row.staff.id;

    const canDownload = [
      DOCUMENT_STATUSES.completed,
      DOCUMENT_STATUSES.submitted,
    ].includes(row.status);

    const canDelete =
      isOwner &&
      [DOCUMENT_STATUSES.created, DOCUMENT_STATUSES.draft].includes(row.status);

    const canSubmit = isOwner && row.status === DOCUMENT_STATUSES.completed;

    const canRevert =
      hasFlag(REVERT_DOCUMENT, meStore.features) &&
      [DOCUMENT_STATUSES.completed, DOCUMENT_STATUSES.submitted].includes(
        row.status,
      );

    return [
      <GridActionsCellItem
        icon={
          <Icon
            name={IconNames.Download}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        label="Download PDF"
        showInMenu={true}
        disabled={!canDownload}
        onClick={() => sessionDocumentStore.downloadPDF(row.id)}
      />,
      <GridActionsCellItem
        icon={
          <Icon
            name={IconNames.Page}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        label="View document"
        showInMenu={true}
        onClick={() => {
          navigate(getDocumentUrl(row.member.id, row.id));
        }}
      />,
      <GridActionsCellItem
        icon={
          <Icon
            name={IconNames.Trash}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        label="Delete document"
        showInMenu={true}
        onClick={() => onDeleteAction?.(row)}
        disabled={!canDelete}
      />,
      <GridActionsCellItem
        icon={
          <Icon
            name={IconNames.CheckCircle}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        label="Tag as Submitted"
        showInMenu={true}
        onClick={() => onSubmittedAction?.(row)}
        disabled={!canSubmit}
      />,
      <GridActionsCellItem
        icon={
          <Icon
            name={IconNames.Redo}
            size={22}
            color={Theme.colorPalette.darkest}
          />
        }
        label="Revert Document"
        showInMenu={true}
        onClick={() => onRevertAction?.(row)}
        disabled={!canRevert}
      />,
    ];
  };

  const columnMap = {
    actions: {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      flex: 1,
      maxWidth: 90,
      getActions: ({row}) => getRowActions({row}),
    },
    documentDescription: {
      field: 'document',
      headerName: 'Document',
      type: 'date',
      flex: 1,
      valueGetter: ({row}) =>
        row.dateOfService ? moment(row.dateOfService).toDate() : null,
      renderCell: DocumentDescriptionCell,
    },
    dateOfService: {
      field: 'dateOfService',
      headerName: 'Date of service',
      type: 'date',
      flex: 1,
      valueGetter: ({row}) =>
        row.dateOfService ? moment(row.dateOfService).toDate() : null,
      renderCell: DateOfServiceCell,
    },
    didMemberAttend: {
      field: 'didMemberAttend',
      headerName: 'Attendance',
      flex: 1,
      valueGetter: ({row}) => {
        let value = PLACEHOLDER_TEXT;
        if (row.didMemberAttend === true) {
          value = 'Attended';
        } else if (row.didMemberAttend === false) {
          value = 'Did not attend';
        }
        return value;
      },
    },
    member: {
      field: 'member',
      headerName: 'Member',
      flex: 1,
      renderCell: MemberCell,
    },
    sessionType: {
      field: 'sessionType',
      headerName: 'Session Type',
      flex: 1,
      valueGetter: ({row}) => {
        const match = SESSION_TYPES.find(
          (option) => option.value === row.sessionType,
        );
        return match ? match.label : PLACEHOLDER_TEXT;
      },
    },
    staff: {
      field: 'staff',
      headerName: 'Support Staff',
      flex: 1,
      renderCell: StaffCell,
    },
    status: {
      field: 'status',
      headerName: 'Document status',
      flex: 1,
      renderCell: StatusCell,
      minWidth: 240,
    },
    statusBadge: {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: StatusBadgeCell,
      minWidth: 120,
    },
    typeOfService: {
      field: 'typeOfService',
      headerName: 'Type of service',
      flex: 1,
      renderCell: ServiceTypeCell,
    },
  };

  return columnMap;
};
