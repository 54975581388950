// @flow
import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {observer} from 'mobx-react';
import {
  Box,
  Stack,
  Typography,
  Select,
  Button,
  Icon,
  IconNames,
} from '@wellstone-solutions/web';
import {useStores} from 'hooks/useStores';
import {HabitListItem} from './HabitListItem';
import {useMemberHabits} from 'hooks/useMemberHabits';
import {showAlert, ALERT_TYPES} from 'utils/showAlert';
import {Theme} from '@wellstone-solutions/common';

const DEFAULT_CATEGORY = 'All';

export const HabitSchedule: Node = observer(() => {
  const [selectedCategory, setSelectedCategory] = useState(DEFAULT_CATEGORY);
  const [localFilteredHabits, setLocalFilteredHabits] = useState([]);
  const [originalFilteredHabits, setOriginalFilteredHabits] = useState([]);
  const [modifiedHabits, setModifiedHabits] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const {memberDetailStore, organizationStore} = useStores();

  const organizationId = organizationStore.organizationId;
  const currentMember = memberDetailStore.currentMember?.id;

  const {
    memberHabits,
    organizationHabits,
    isLoading,
    saveMemberHabits,
  } = useMemberHabits(organizationId, currentMember);
  useEffect(() => {
    if (!isLoading) {
      const habitsWithState = organizationHabits.map((habit) => {
        const memberHabit = memberHabits.find((h) => h.habit.id === habit.id);
        return {
          ...habit,
          checked: Boolean(memberHabit),
          frequency: memberHabit?.frequency || '',
          interval: memberHabit?.interval || '',
          habitType: memberHabit?.habitType || null,
        };
      });
      setLocalFilteredHabits(habitsWithState);
      setOriginalFilteredHabits(habitsWithState);
      setModifiedHabits(
        habitsWithState.filter(
          (habit) => habit.checked && habit.habitType !== 'personal',
        ),
      );
    }
  }, [organizationHabits, memberHabits, isLoading]);

  const uniqueHabitCategories = [
    ...new Set(
      organizationHabits.flatMap((h) => h.tags.map((tag) => tag.name)),
    ),
  ].sort((a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });

  const handleCategoryChange = (v) => {
    setSelectedCategory(v);
  };

  const handleCheckboxChange = (habitId, checked) => {
    setIsDirty(true);
    const updatedHabits = localFilteredHabits.map((habit) =>
      habit.id === habitId
        ? {
            ...habit,
            checked,
            habitType: checked ? 'assigned' : null,
            frequency: checked ? habit.frequency : '', // Reset frequency if unchecked
            interval: checked ? habit.interval : '', // Reset interval if unchecked
          }
        : habit,
    );
    setLocalFilteredHabits(updatedHabits);
    updateModifiedHabits(updatedHabits);
    validateHabits(updatedHabits);
  };

  const handleFrequencyChange = (habitId, newFrequency) => {
    setIsDirty(true); // Set to true on interaction
    const updatedHabits = localFilteredHabits.map((habit) =>
      habit.id === habitId ? {...habit, frequency: newFrequency} : habit,
    );
    setLocalFilteredHabits(updatedHabits);
    updateModifiedHabits(updatedHabits);
    validateHabits(updatedHabits);
  };

  const handleIntervalChange = (habitId, newInterval) => {
    setIsDirty(true); // Set to true on interaction
    const updatedHabits = localFilteredHabits.map((habit) =>
      habit.id === habitId ? {...habit, interval: newInterval} : habit,
    );
    setLocalFilteredHabits(updatedHabits);
    updateModifiedHabits(updatedHabits);
    validateHabits(updatedHabits);
  };

  const handleUpdateHabits = async () => {
    try {
      // force correct format for request
      const updatedHabits = modifiedHabits.map((habit) => ({
        habitId: habit.id,
        frequency: habit.frequency,
        interval: habit.interval,
      }));

      await saveMemberHabits(updatedHabits);
      setIsDirty(false);
      showAlert('Successfully updated member habits.', ALERT_TYPES.SUCCESS);
    } catch (error) {
      showAlert(
        'Failed to update member habits. Please try again.',
        ALERT_TYPES.ERROR,
      );
    }
  };

  const handleResetDefaults = () => {
    setLocalFilteredHabits(originalFilteredHabits);
    setModifiedHabits(
      originalFilteredHabits.filter(
        (habit) => habit.checked && habit.habitType !== 'personal',
      ),
    );
    validateHabits(originalFilteredHabits);
    setIsDirty(false);
    showAlert(
      'Member habits have been reset to their defaults.',
      ALERT_TYPES.INFO,
    );
  };

  const updateModifiedHabits = (updatedHabits) => {
    setModifiedHabits(
      updatedHabits.filter(
        (habit) => habit.checked && habit.habitType !== 'personal',
      ),
    );
  };

  const validateHabits = (updatedHabits) => {
    const valid = updatedHabits.every(
      (habit) => !habit.checked || (habit.frequency && habit.interval),
    );
    setIsValid(valid);
  };

  const filteredHabits = localFilteredHabits.filter((habit) =>
    selectedCategory === DEFAULT_CATEGORY
      ? true
      : habit.tags.some((tag) => tag.name === selectedCategory),
  );

  return (
    <Stack direction="column" sx={styles.container}>
      <Typography variant="h4" sx={styles.heading}>
        Healthy Habits
      </Typography>
      <Typography sx={styles.description}>
        Habits support those in recovery by anchoring them in good routines to
        build a healthier lifestyle. Work with your client to set a collection
        of healthy habits that suit their needs. Clients can also select
        additional habits from within the Companion app.
      </Typography>

      <Stack direction="row" justifyContent="space-between" sx={styles.actions}>
        <Box sx={styles.dropdownContainer}>
          <Select
            label="Categories"
            items={[
              {label: 'All Categories', value: DEFAULT_CATEGORY},
              ...uniqueHabitCategories.map((category) => ({
                label: category,
                value: category,
              })),
            ]}
            value={selectedCategory}
            onChange={(e) => handleCategoryChange(e.target.value)}
          />
        </Box>

        <Stack direction="row" alignItems="center" sx={styles.buttonGroup}>
          <Button
            color="primary"
            onClick={handleResetDefaults}
            sx={styles.resetButton}
            startIcon={
              <Icon
                name={IconNames.RefreshDouble}
                strokeWidth={2}
                color={Theme.colorPalette.primary}
                size={16}
              />
            }
            disabled={!isDirty}>
            Reset defaults
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdateHabits}
            sx={styles.updateButton}
            startIcon={
              <Icon
                name={IconNames.SendDiagonal}
                strokeWidth={2}
                color={
                  isValid
                    ? Theme.colorPalette.lightest
                    : Theme.colorPalette.mediumDark
                }
                size={16}
              />
            }
            disabled={!isValid || !isDirty}>
            Update
          </Button>
        </Stack>
      </Stack>

      <Box sx={styles.habitList}>
        {filteredHabits.map((habit) => (
          <HabitListItem
            key={habit.id}
            habit={habit}
            handleCheckboxChange={handleCheckboxChange}
            handleFrequencyChange={handleFrequencyChange}
            handleIntervalChange={handleIntervalChange}
          />
        ))}
      </Box>
    </Stack>
  );
});

const styles = {
  container: {
    padding: Theme.spacing.micro,
  },
  heading: {
    marginBottom: Theme.spacing.micro,
    fontSize: Theme.spacing.big,
    fontWeight: 'bold',
  },
  description: {
    marginBottom: Theme.spacing.mini,
    fontSize: Theme.spacing.medium,
    lineHeight: '1.6',
  },
  actions: {
    marginBottom: Theme.spacing.small,
    alignItems: 'center',
  },
  dropdownContainer: {
    flex: 1,
    marginRight: Theme.spacing.big,
  },
  buttonGroup: {
    gap: Theme.spacing.mini,
  },
  resetButton: {
    marginRight: Theme.spacing.mini,
  },
  updateButton: {
    minWidth: Theme.spacing.gap,
    borderRadius: 20,
  },
  habitList: {
    display: 'grid',
    gap: Theme.spacing.mini,
  },
};
