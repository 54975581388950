import React from 'react';
import {Animated} from 'react-animated-css';
import {inject, observer} from 'mobx-react';
import {Theme} from '@wellstone-solutions/common';
import {Icon, IconNames} from '@wellstone-solutions/web';
import {Palette} from '../../palette';
import moment from 'moment';
import {Actions} from '../../constants/Messages';
import {
  breakMessageIntoTypedParts,
  MESSAGE_PART_TYPES,
} from 'utils/conversations';
import {ResourceMessage} from './ResourceMessage';

const formatMessageWithLinks = (content: string, openLink) => {
  const parts = breakMessageIntoTypedParts(content);
  return parts.map((part, i) => {
    const message = part.msg;
    if (part.type === MESSAGE_PART_TYPES.link) {
      const url = message.trim();
      const formattedUrl =
        url.toLowerCase().indexOf('http') === 0 ? url : `https://${url}`;

      return (
        <a href={formattedUrl} target="_blank" style={styles.msgLink} key={i}>
          {message}
        </a>
      );
    }

    return <span key={i}>{message}</span>;
  });
};

class PfMessage extends React.Component {
  render() {
    const {owner} = this.props;
    const {message} = this.props.message;
    // const timestamp = moment(timetoken/10000000).format("h:mmA MMM Do");
    const content =
      message.data.action &&
      [
        Actions.SHARED_MEETING,
        Actions.SHARED_RESOURCE,
        Actions.SHARED_RESOURCE_ANSWER,
        Actions.SHARED_STORY,
      ].includes(message.data.action)
        ? JSON.parse(message.data.content)
        : message.data.content;

    const isSharedResource =
      message.data.action === Actions.SHARED_RESOURCE_ANSWER ||
      message.data.action === Actions.SHARED_RESOURCE;

    return (
      <Animated
        animationIn="fadeInUp"
        animationOut="fadeOutDown"
        animationInDuration={250}
        animationOutDuration={250}
        isVisible={!!message}>
        <div style={styles.msgRow}>
          {owner === 'other' ? (
            <div style={styles.initial}>{message.data.name.charAt(0)}</div>
          ) : null}

          {isSharedResource ? (
            <ResourceMessage resource={content} owner={owner} />
          ) : (
            <div style={{...styles[owner], ...styles.msg}}>
              {message.data.action === Actions.SHARED_RESOURCE ? (
                <div style={styles.resourceContent}>
                  <Icon
                    style={styles.resourceIcon}
                    name={IconNames.GoogleDocs}
                    color={'#FFF'}
                  />
                  <div style={styles.resourceTitle}>{content.title}</div>
                </div>
              ) : message.data.action === Actions.SHARED_MEETING ? (
                <div style={styles.resourceContent}>
                  <Icon
                    style={styles.resourceIcon}
                    name={IconNames.Calendar}
                    color={Palette.info}
                  />
                  <div>
                    <div style={styles.resourceTitle}>{content.name}</div>
                    <div style={styles.resourceInfo}>
                      {content.day} {moment(content.time).format('h:mmA')}
                    </div>
                  </div>
                </div>
              ) : (
                <span>{formatMessageWithLinks(content)}</span>
              )}
            </div>
          )}
          <div
            style={{
              ...styles.fromNow,
              ...(owner === 'other' ? styles.leftDate : styles.rightDate),
              ...(message.verified && styles.verifiedDate),
            }}>
            {moment(message.data.authorTT).fromNow()}
          </div>
        </div>
      </Animated>
    );
  }
}

const styles = {
  msgRow: {
    width: '100%',
    float: 'left',
    marginBottom: 30,
    marginTop: 10,
    position: 'relative',
  },
  msg: {
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth: '500px', //"calc(100% - 60px)",
    position: 'relative',
    wordWrap: 'break-word',
  },
  msgLink: {
    paddingRight: '4px',
    paddingLeft: '4px',
    color: Theme.colorPalette.teal,
  },
  me: {
    backgroundColor: Palette.PRIMARY,
    color: 'white',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 0,
    float: 'right',
    textAlign: 'right',
  },
  initial: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: Palette.GREY_1,
    color: Palette.GREY_4,
    textAlign: 'center',
    height: '30px',
    width: '30px',
    lineHeight: '30px',
    borderRadius: '50%',
  },
  other: {
    backgroundColor: Palette.LIGHT_INFO,
    color: Palette.DARK_INFO,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 0,
    float: 'left',
    left: '35px',
  },
  resourceContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  resourceIcon: {
    marginRight: 10,
  },
  resourceTitle: {
    fontWeight: 'bold',
    flex: 1,
  },
  resourceInfo: {
    fontSize: 10,
  },
  verificationCheck: {
    position: 'absolute',
    top: -12,
  },
  meCheck: {
    right: 0,
  },
  otherCheck: {
    left: 0,
  },
  fromNow: {
    fontSize: 9,
    bottom: -20,
    position: 'absolute',
  },
  leftDate: {
    left: 35,
  },
  rightDate: {
    right: 0,
  },
  verifiedDate: {
    color: Palette.SUCCESS,
  },
};

export default inject('meStore')(observer(PfMessage));
