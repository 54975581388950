// @flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Box, Stack} from '@wellstone-solutions/web';
import {
  SessionDocumentList,
  CreateSessionDocumentDialog,
} from 'modules/documentation';
//import {BridgeEventSources, Events} from '@wellstone-solutions/common';
//import {useEventSource} from 'hooks/useEventSource';
import {useStores} from 'hooks/useStores';
import {AccessControl} from 'modules/rbac';
import {PageHeader} from 'components/PageHeader';
import {PAGE_DESCRIPTION} from '../../constants';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const DocumentationLanding = (): Node => {
  const {meStore, RBACStore, appUIStore} = useStores();
  const theme = useTheme();
  //useEventSource(BridgeEventSources.DOCUMENTATION_PAGE);
  const medBreakPoint = useMediaQuery('@media (max-width:749.95px)');
  const intermedBreakPoint = useMediaQuery('@media (max-width:999.95px)');
  const largeBreakPoint = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    appUIStore.setBreadcrumbs([]);
    // Commented out until VIEWED_DOCUMENTATION is available in API
    //eventStore.addEvent(Events.VIEWED_DOCUMENTATION);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUIStore]);

  const showMyDocuments = RBACStore.hasAccess(
    AccessControl.documentation.viewMySessionDocs,
  );

  const showProgramFilter = RBACStore.hasAccess(
    AccessControl.documentation.viewProgramFilter,
  );

  const defaultParams = showMyDocuments
    ? {staff: meStore.activeMembership.id}
    : {};

  let allColumns;
  if (medBreakPoint) {
    allColumns = ['documentDescription', 'actions'];
  } else if (intermedBreakPoint) {
    allColumns = ['dateOfService', 'member', 'staff', 'statusBadge', 'actions'];
  } else if (largeBreakPoint) {
    allColumns = [
      'dateOfService',
      'member',
      'staff',
      'sessionType',
      'status',
      'actions',
    ];
  } else {
    allColumns = [
      'dateOfService',
      'member',
      'staff',
      'sessionType',
      'typeOfService',
      'status',
      'actions',
    ];
  }

  // Include staff when showing all documents
  const columns = showMyDocuments
    ? allColumns.filter((c) => c !== 'staff')
    : allColumns;

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <PageHeader
          title="Documentation"
          description={PAGE_DESCRIPTION}
        />
        <CreateSessionDocumentDialog compact={medBreakPoint} />
      </Stack>
      <Box mb={1}>
        <SessionDocumentList
          defaultParams={defaultParams}
          columnNames={columns}
          showProgramFilter={showProgramFilter}
          showMemberFilter={true}
          showStaffFilter={!showMyDocuments}
          {...(medBreakPoint && {
            rowHeight: () => 115,
            showGridHeader: false,
          })}
          showDocumentCount={true}
        />
      </Box>
    </>
  );
};
