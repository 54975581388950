// @flow
import React, {useState} from 'react';
import type {Node} from 'react';
import {useCreateDocumentation} from '../hooks';
import {Theme, Roles} from '@wellstone-solutions/common';
import {DirectorySearch} from 'modules/organization';
import {
  Avatar,
  Button,
  Fab,
  Stack,
  Box,
  Typography,
  Progress,
  Icon,
  IconNames,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@wellstone-solutions/web';

type propsType = {
  compact?: boolean,
};
export const CreateSessionDocumentDialog = ({
  compact = false,
}: propsType): Node => {
  const [isOpen, setIsOpen] = useState(false);
  const [member, setMember] = useState(null);
  const {createDocumentation, isLoading} = useCreateDocumentation();

  const Title = 'Create new document';
  const Text = 'What member would you like to create a session document for?';

  const handleCreateDocumentation = () => {
    member && createDocumentation({memberId: member.id});
  };

  const handleButtonClick = () => {
    setIsOpen(true);
  };

  const handleCancelCreate = () => {
    setIsOpen(false);
    setMember(null);
  };

  return (
    <>
      <Box>
        {compact ? (
          <Fab
            size="medium"
            aria-label="create document"
            color="primary"
            onClick={handleButtonClick}
            data-testid="create-document-button">
            <Icon name={IconNames.Plus} color={Theme.colorPalette.onPrimary} />
          </Fab>
        ) : (
          <Fab
            variant="extended"
            aria-label="create document"
            color="primary"
            onClick={handleButtonClick}
            sx={{whiteSpace: 'nowrap'}}
            data-testid="create-document-button">
            <Icon name={IconNames.Plus} color={Theme.colorPalette.onPrimary} />{' '}
            New Document
          </Fab>
        )}
      </Box>
      <Dialog open={isOpen} sx={styles.dialog}>
        <DialogTitle sx={{p: 0}}>
          <Stack sx={{marginBottom: 1}}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{marginBottom: 2}}>
              <Avatar sx={styles.avatar}>
                <Icon
                  name={IconNames.Page}
                  size={24}
                  color={Theme.colorPalette.primary}
                />
              </Avatar>
              <Button
                color="primary"
                onClick={handleCancelCreate}
                sx={{padding: 0, minWidth: 32, height: 32}}>
                <Icon
                  name={IconNames.Xmark}
                  size={28}
                  color={Theme.colorPalette.primary}
                />
              </Button>
            </Stack>
            <Typography variant="h5" sx={{fontSize: '28px', fontWeight: 700}}>
              {Title}
            </Typography>
            <Typography
              variant="body2"
              color={Theme.colorPalette.dark}
              sx={{fontSize: '16px'}}>
              {Text}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{'&.MuiDialogContent-root': {p: 0, pt: 2}}}>
          <DirectorySearch
            label="Search for member"
            roles={[Roles.PATIENT]}
            onOptionSelect={(newValue) => {
              setMember(newValue);
            }}
            value={member}
            testId="member-search"
          />
          <Typography
            variant="body2"
            color={Theme.colorPalette.dark}
            sx={{px: '14px'}}>
            Enter name or email address
          </Typography>
        </DialogContent>
        <DialogActions sx={{p: 0, pt: 3}}>
          <Button
            onClick={handleCancelCreate}
            sx={{textTransform: 'none'}}
            data-testid="cancel-create-button">
            Cancel
          </Button>
          <Button
            data-testid="submit-button"
            onClick={handleCreateDocumentation}
            color="primary"
            variant="contained"
            disabled={isLoading || !member}
            sx={styles.button}
            startIcon={
              isLoading ? (
                <Progress
                  size="1.2em"
                  color="inherit"
                  data-testid="loading-spinner"
                />
              ) : (
                <Icon name={IconNames.Page} size={24} color="inherit" />
              )
            }>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const styles = {
  dialog: {
    '& .MuiPaper-root': {
      width: '537px',
      borderRadius: 3,
      padding: 3,
    },
  },
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: `${Theme.colorPalette.primary4}`,
  },
  button: {
    whiteSpace: 'nowrap',
    fontSize: 16,
    textTransform: 'none',
    borderRadius: '20px',
    px: 3,
  },
};
