// @flow
import React from 'react';
import type {Node} from 'react';
import {Theme} from '@wellstone-solutions/common';
import {DOCUMENT_STATUSES, DOCUMENT_STATUS_LOOKUP} from '../../constants';
import {ColorChip} from 'components/ColorChip';

export const StatusChip = ({
  status,
  sx,
}: {
  status: $Values<typeof DOCUMENT_STATUSES>,
  sx?: any,
}): Node => {
  const displayStatus = DOCUMENT_STATUS_LOOKUP[status].label;
  const stylesByStatus = {
    [DOCUMENT_STATUSES.created]: Theme.colorPalette.blue,
    [DOCUMENT_STATUSES.draft]: Theme.colorPalette.blue,
    [DOCUMENT_STATUSES.error]: Theme.colorPalette.red,
    [DOCUMENT_STATUSES.completed]: Theme.colorPalette.primary,
    [DOCUMENT_STATUSES.submitted]: Theme.colorPalette.positive,
    [DOCUMENT_STATUSES.reverted]: Theme.colorPalette.orange,
  };

  return (
    <ColorChip
      label={displayStatus}
      color={stylesByStatus[status]}
      sx={{width: '100px', ...sx}}
    />
  );
};
