// @flow
import React from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Theme} from '@wellstone-solutions/common';
import {Stack, Typography} from '@wellstone-solutions/web';
import {PLACEHOLDER_TEXT} from 'modules/datagrid';
import {DATE_FORMAT_LONG} from 'modules/documentation/constants';
import {getStatusDate} from 'modules/documentation/utils';
import {StatusChip} from '../SessionDocument/StatusChip';
import type {UISessionDocumentType} from '../../model/types';

type InfoType = {label: string, value: string, isLink?: boolean};
export const InfoRow = ({label, value, isLink = false}: InfoType): Node => (
  <Typography noWrap component="div">
    <Typography
      sx={{
        fontWeight: Theme.typography.weight.bold,
        fontSize: Theme.typography.size.s2,
        lineHeight: 1.2,
      }}
      component="span">
      {label}:{' '}
    </Typography>
    <Typography
      component="span"
      sx={{
        fontSize: Theme.typography.size.s2,
        color: isLink ? Theme.colorPalette.link : Theme.colorPalette.darkest,
        lineHeight: 1.2,
      }}>
      {value}
    </Typography>
  </Typography>
);

type PropsType = {
  document: UISessionDocumentType,
  showStaff?: boolean,
};

export const DocumentCell = ({document, showStaff = true}: PropsType): Node => {
  const {member, staff} = document;
  const statusDate = getStatusDate({documentation: document});

  const dateOfService = document.dateOfService
    ? moment(document.dateOfService).format(DATE_FORMAT_LONG)
    : PLACEHOLDER_TEXT;

  return (
    <Stack sx={{justifyContent: 'center', height: '100%', width: '100%'}}>
      <InfoRow label="Date of Service" value={dateOfService} isLink={true} />
      <InfoRow label="Member" value={member.name} />
      {showStaff && <InfoRow label="Support Staff" value={staff.name} />}
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{width: '100%', mt: '5px'}}>
        <StatusChip status={document.status} sx={{height: '24px'}} />
        <Typography variant="body2" sx={{fontSize: '12px'}} noWrap>
          {statusDate}
        </Typography>
      </Stack>
    </Stack>
  );
};
