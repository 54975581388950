// @flow
// React
import React, {useState, useEffect, useMemo, useRef} from 'react';
import type {Node} from 'react';
import moment from 'moment';
import {Api, Events, Hooks, Models, Theme} from '@wellstone-solutions/common';
import {
  Box,
  DataGrid,
  usePaging,
  Stack,
  DatePicker,
} from '@wellstone-solutions/web';
import Button from 'components/tutorial/button';
import {EventEmitter} from 'utils/EventEmitter';
import {useMemberColumnMap} from 'modules/member';
import {useStores} from 'hooks/useStores';
import {groupStartDatePrecedesProgramStartDate} from 'modules/group/utils';

const {usePaginatedData} = Hooks;
const {Member, GroupAdmission} = Models;

type PropsType = {
  group: Object,
  groupListRefetch: () => Promise<void>,
};

const MIN_HEIGHT = '150px';

export const AddMemberToGroup = ({
  group,
  groupListRefetch,
}: PropsType): Node => {
  const initialLoad = useRef(true);
  const {eventStore, meStore} = useStores();
  const [preSelectedMembers, setPreSelectedMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [startDate, setStartDate] = useState(new moment());
  const [endDate, setEndDate] = useState(new moment().add(90, 'days'));
  const [isSaving, setIsSaving] = useState(false);

  const addMembers = async () => {
    setIsSaving(true);
    await Promise.all(
      selectedMembers.map((id) => {
        if (preSelectedMembers.find((psId) => id === psId)) {
          // do not save this member, they already exist
          return Promise.resolve();
        }
        return Api.Instance.current().post(
          GroupAdmission.routes.index(meStore.organizationId, group.id),
          {
            member_id: id,
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
          },
        );
      }),
    );
    // refetch the group members list after adding member to it
    groupListRefetch?.();
    setIsSaving(false);
    EventEmitter.dispatch('closeModal', {status: 'ok'});
  };

  const initialSortField = 'name';
  const initialSortOrder = 'asc';

  const {
    offset,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
  } = usePaging({
    initialSortField,
    initialSortOrder,
    isPersisted: false,
    initialPageSize: 10,
  });

  const params = useMemo(
    () => ({
      role: 'patient',
      program_id: group.programs[0]?.id,
    }),
    [group],
  );

  const queryResults = usePaginatedData({
    url: Member.routes.index(meStore.me.membership.organization.id),
    params,
    dataTransformer: ({members, total}) => ({
      members: members.map(Member.toUI),
      total,
    }),
    pageSize,
    currentPage: offset,
    sortField,
    sortOrder,
  });

  const refetchData = () => {
    if (queryResults.refetch) {
      queryResults.refetch({
        pageSize,
        currentPage: offset,
        params,
        sortField,
        sortOrder,
      });
    }
  };

  useEffect(() => {
    if (!initialLoad.current) {
      refetchData();
    }
    initialLoad.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, currentPage, pageSize, sortField, sortOrder]);

  useEffect(() => {
    const existingMembers = (queryResults.data?.members || [])
      .map((member) => (!canSelectMember(member) ? member.id : null))
      .filter(Boolean);

    if (existingMembers.length !== preSelectedMembers.length) {
      setPreSelectedMembers(existingMembers);
      setSelectedMembers(existingMembers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResults.data]);

  // Can only select a member if they are in a program
  // and not in the current group
  const canSelectMember = (member) => {
    const isInProgram = member.admissions.some(
      (admission) => !admission.discharge_date,
    );

    if (!isInProgram) {
      return false;
    }

    const existingGroupAdmission = member.groupAdmissions.find((admission) => {
      return admission.group.id === group.id && !admission.discharge_date;
    });

    return !existingGroupAdmission;
  };

  const checkRowSelectable = (row) => {
    return (
      canSelectMember(row) &&
      groupStartDatePrecedesProgramStartDate({
        member: row,
        selectedStartDate: startDate,
        programId: params.program_id,
      })
    );
  };

  const columnMap = useMemberColumnMap({programId: params?.program_id});
  const columns = [
    columnMap.name,
    columnMap.groups,
    columnMap.programStartDate,
  ];

  const newlySelectedMembers =
    selectedMembers.length - preSelectedMembers.length;
  return (
    <>
      <Box
        sx={{
          maxHeight: `calc(60vh - ${MIN_HEIGHT})`,
          overflowY: 'auto',
        }}>
        <DataGrid
          autoHeight
          checkboxSelection
          keepNonExistentRowsSelected
          getRowHeight={() => 'auto'}
          loading={queryResults.isLoading}
          rows={queryResults.data?.members ?? []}
          rowsPerPageOptions={[5, 10, 25]}
          getRowId={(row) => row.id}
          columns={columns}
          noRowsText="No members"
          isRowSelectable={({row}) => checkRowSelectable(row)}
          selectionModel={selectedMembers}
          onSelectionModelChange={(newSelection) => {
            setSelectedMembers(newSelection);
          }}
          page={currentPage}
          pageSize={pageSize}
          paginationMode="server"
          sortingMode="server"
          initialState={{
            sorting: {
              sortModel: [{field: initialSortField, sort: initialSortOrder}],
            },
          }}
          rowCount={queryResults.data?.total ?? 0}
          onPageChange={(updatedPage) => setCurrentPage(updatedPage)}
          onPageSizeChange={(updatedPageSize) => setPageSize(updatedPageSize)}
          onSortModelChange={(sortedColumns) => {
            let updatedSortField = '';
            let updatedSortOrder = '';
            if (sortedColumns?.length > 0) {
              const [sortedColumn] = sortedColumns;
              updatedSortField = sortedColumn.field;
              updatedSortOrder = sortedColumn.sort;
              eventStore.addEvent(Events.USER_SORTED_TABLE, {
                sort_field: updatedSortField,
                sort_order: updatedSortOrder,
                table: 'AddMemberToGroup',
              });
            }
            setSortField(updatedSortField);
            setSortOrder(updatedSortOrder);
          }}
        />
      </Box>
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{minHeight: MIN_HEIGHT}}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          sx={{
            borderTop: `1px solid ${Theme.colorPalette.mediumLight}`,
            mb: 2,
            pt: 2,
          }}>
          <Stack sx={{maxWidth: '400px'}}>
            <DatePicker
              label="Start Date"
              fieldName="startDate"
              onChange={(date) => {
                setStartDate(date);
              }}
              value={startDate.format('YYYY-MM-DD')}
            />
          </Stack>
          <Stack sx={{maxWidth: '400px'}}>
            <DatePicker
              label="End Date"
              fieldName="endDate"
              onChange={(date) => {
                setEndDate(date);
              }}
              value={endDate.format('YYYY-MM-DD')}
            />
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between">
          <Button
            color="primary"
            onClick={addMembers}
            disabled={newlySelectedMembers <= 0 || isSaving}
            id="add-group-member-modal-button">
            Add Member{newlySelectedMembers > 1 && 's'}
          </Button>
          <Button
            color="danger"
            onClick={() => EventEmitter.dispatch('closeModal', null)}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
