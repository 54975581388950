import React, {useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';

// Bulma
import Box from 'react-bulma-components/lib/components/box';

// WS
import {useStores} from 'hooks/useStores';
import {ListItem} from './ListItem';

const List = (props) => {
  const {select, selected} = props;
  const [isLoading, setIsLoading] = useState(true);
  const {channelStore, organizationStore} = useStores();
  const scrollBoxRef = useRef(null);

  //On mount get initial set of channels
  useEffect(() => {
    async function getChannels() {
      await channelStore.loadChannels(
        false,
        false,
        organizationStore.organizationId,
      );
      setIsLoading(false);
    }
    getChannels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle infinite scroll loading of new channels
  useEffect(() => {
    const element = scrollBoxRef.current;

    const handleScroll = () => {
      async function getChannels() {
        await channelStore.loadChannels();
        setIsLoading(false);
      }
      const position = element.scrollTop;
      const height = element.scrollHeight - element.clientHeight;
      // the Math.ceil accounts for the inexact position values when the screen is zoomed
      if (height > 100 && height <= Math.ceil(position)) {
        setIsLoading(true);
        getChannels();
      }
    };

    if (element) {
      element.addEventListener('scroll', handleScroll, {passive: true});

      return () => {
        element.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box style={styles.box}>
      <div ref={scrollBoxRef} style={styles.scrollBox}>
        {channelStore.channels.map((channel) => {
          if (!channel.members.length) {
            return null;
          }
          const channelMember = channel.members[0];
          const activityDate = channel.data?.last_channel_activity
            ? new Date(channel.data.last_channel_activity)
            : null;
          const uc =
            channelStore.unreadChannels[
              channelStore.findUnreadChannel(channel.name)
            ];
          return (
            <ListItem
              key={channelMember.id}
              activityDate={activityDate}
              channel={channel}
              channelMember={channelMember}
              select={select}
              selected={selected}
              unreadCount={uc?.messageCount}
            />
          );
        })}
        {isLoading && (
          <div style={styles.loadingContainer}>
            <div className="dot-flashing" />
          </div>
        )}
      </div>
    </Box>
  );
};

const styles = {
  box: {},
  scrollBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  loadingContainer: {
    width: '100%',
    textAlign: 'center',
    position: 'relative',
    height: 0,
    marginTop: '5px',
  },
};

export default observer(List);
