// @flow
import {Api} from '@wellstone-solutions/common';
import * as Sentry from '@sentry/react';
import {proxy} from '../utils';
import {hasFlag, SENTRY_DEBUG} from 'constants/FeatureFlags';

export const handleErrors = async (error: any): Promise<any> => {
  const {status, config} = error;

  // Auth refresh failed, clear user data to sign out
  if (status === 401) {
    Api.Instance.v2().abort();
    if (proxy.stores.meStore) {
      proxy.stores.meStore.clearData();
    }
  }

  if (status >= 500) {
    const error = new Error(
      `Api Call Failed: Status: ${status} ${config?.method || ''} ${
        config?.url
      }`,
    );

    Sentry.withScope((scope) => {
      scope.setFingerprint(`${config?.method || ''}:${config?.url || ''}`);
      if (
        proxy.stores.meStore &&
        hasFlag(SENTRY_DEBUG, proxy.stores.meStore.features)
      ) {
        scope.setExtra('payload', config);
      }
      Sentry.captureException(error);
    });
  }

  return Promise.reject(error);
};
