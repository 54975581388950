// @flow
import moment from 'moment';

import Badge from '@mui/material/Badge';
import {Avatar, Box, Button} from '@wellstone-solutions/web';
import {Theme} from '@wellstone-solutions/common';

import {MEMBER_ROLES} from '../../../constants/Members';

interface ListItemProps {
  activityDate?: null | Date;
  channel: {id: string};
  channelMember: {id: string, name: string, role: string};
  select: ({id: string}) => void;
  selected?: null | {id: string};
  unreadCount: number;
}

export const ListItem = ({
  activityDate,
  channel,
  channelMember,
  select,
  selected,
  unreadCount,
}: ListItemProps): React$Node => {
  const avatarInitials = () => {
    const parts = channelMember.name.split(' ');

    return parts.length > 1
      ? `${parts[0][0]}${parts[1][0]}`.toUpperCase()
      : `${parts[0][0]}`.toUpperCase();
  };

  return (
    <Button
      onClick={() => select(channel)}
      disableRipple
      sx={{
        ...styles.buttonContainer,
        ...(selected?.id === channel.id && styles.selected),
      }}>
      <Badge
        data-testid="badge"
        overlap="circular"
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        badgeContent={
          Number.isInteger(unreadCount) &&
          unreadCount > 0 && (
            <Avatar sx={styles.unreadCount}>{unreadCount}</Avatar>
          )
        }>
        <Avatar
          data-testid="avatar"
          sx={styles.avatar}
          alt={channelMember.name}>
          {avatarInitials()}
        </Avatar>
      </Badge>
      <Box sx={styles.member}>
        <Box sx={styles.memberName}>{channelMember.name}</Box>
        {channelMember.role in MEMBER_ROLES && (
          <Box sx={styles.memberRole}>{MEMBER_ROLES[channelMember.role]}</Box>
        )}
      </Box>
      {activityDate && (
        <Box data-testid="activity-date" sx={styles.activityDate}>
          {moment(activityDate).fromNow()}
        </Box>
      )}
    </Button>
  );
};

const styles = {
  buttonContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 14px',
    textTransform: 'none',
    transition: 'background-color 100ms ease-in-out',
    width: '100%',
    '&:hover': {
      backgroundColor: Theme.colorPalette.secondary4,
    },
  },
  selected: {
    backgroundColor: Theme.colorPalette.secondary4,
  },
  avatar: {
    width: 29,
    height: 29,
    fontSize: '0.75rem',
    lineHeight: '12px',
    fontWeight: 'medium',
    color: Theme.colorPalette.light,
    backgroundColor: Theme.colorPalette.primary,
    flexShrink: 0,
  },
  unreadCount: {
    width: 16,
    height: 16,
    fontSize: '0.5rem',
    fontWeight: 700,
    color: Theme.colorPalette.light,
    backgroundColor: Theme.colorPalette.negative,
  },
  member: {
    marginLeft: '8px',
    flexGrow: 1,
    textAlign: 'left',
  },
  memberName: {
    color: Theme.colorPalette.primary,
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  memberRole: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    color: Theme.colorPalette.mediumDark,
  },
  activityDate: {
    fontSize: '0.75rem',
    textAlign: 'right',
    color: Theme.colorPalette.dark,
    flexShrink: 0,
  },
};
