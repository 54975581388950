//@flow
import React, {useEffect} from 'react';
import type {Node} from 'react';
import {Outlet} from 'react-router-dom';
import {useStores} from 'hooks/useStores';
import {ResourcesProvider} from '../contexts';
import {ResourcesHeader} from './ResourcesHeader';

export const ResourcesRoot = (): Node => {
  const {appUIStore, resourceStoreV2} = useStores();

  useEffect(() => {
    appUIStore.setBreadcrumbs([]);
  }, [appUIStore]);

  useEffect(() => {
    resourceStoreV2.getUserResources();
  }, [resourceStoreV2]);

  return (
    <ResourcesProvider>
      <ResourcesHeader />
      <Outlet />
    </ResourcesProvider>
  );
};
