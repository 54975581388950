// @flow
// React
import React, {useState, useEffect} from 'react';
import type {ComponentType, Node} from 'react';

// Third Party
import {observer} from 'mobx-react';
import * as Sentry from '@sentry/react';

// WS
import {useStores} from 'hooks/useStores';
import {Routes as SharedRoutes} from 'components/Routes';
import PfLoader from 'components/shared/PfLoader';
import {
  hasFlag,
  SENTRY_DEBUG,
  MAINTENANCE_MODE_BRIDGE,
} from 'constants/FeatureFlags';
import {Breadcrumbs} from './authorized/Breadcrumbs';
import {getAuthorizedRoutes} from './AuthorizedRoutes';
import {MaintenanceMode} from 'components/MaintenanceMode/MaintenanceMode';

import {SiteNavigation} from 'modules/app/components/SiteNavigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';

export const AuthorizedFrame: ComponentType<> = observer((): Node => {
  const [loading, setLoading] = useState(true);
  const {
    meStore,
    organizationStore,
    groupStore,
    pubnubStore,
    channelStore,
  } = useStores();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const initFrame = async () => {
      if (!meStore.activeMembership) {
        await meStore.setActiveMembership(meStore.me);
      }

      await Promise.all([
        organizationStore.loadOrganization(
          meStore.activeMembership.organization.id,
        ),
        groupStore.loadGroups(meStore.activeMembership.organization.id),
      ]);

      if (hasFlag(SENTRY_DEBUG, meStore.features)) {
        Sentry.setUser({id: meStore.me.id});
      }

      if (!pubnubStore.connected) {
        await pubnubStore.connect();
        channelStore.getUnreadChannels();
      }

      setLoading(false);
    };

    initFrame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <PfLoader />;
  }

  if (hasFlag(MAINTENANCE_MODE_BRIDGE, meStore.features)) {
    return <MaintenanceMode />;
  }

  const authorizedRoutes = getAuthorizedRoutes(meStore);

  return (
    <div className="contained" style={styles.dashboard}>
      {!isMobile && <SiteNavigation />}
      <div style={styles.main}>
        <Breadcrumbs />
        <SharedRoutes routes={authorizedRoutes} />
      </div>
    </div>
  );
});

const styles = {
  dashboard: {
    flexDirection: 'row',
    display: 'flex',
    paddingTop: 80,
    minHeight: 800,
  },
  main: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
    minWidth: 0,
  },
};
